import { InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import * as React from 'react';
import { SearchType } from '../../../types/common.types';
import { makeStyles } from '@mui/styles';
import { colors, theme } from '../../../theme';

const color = colors();
const themes = theme().typography;
export const Search = ({ placeholder, value, onSearchCallback, onEnterSearch }: SearchType) => {
	const classes = useStyles();

	return (
		<TextField
			id="search-bar"
			type="search"
			label={placeholder}
			size="small"
			value={value}
			onChange={(e) => onSearchCallback && onSearchCallback(e.target.value)}
			onKeyPress={(ev) => {
				console.log('enter clicked');
				if (ev.key === 'Enter') {
					onEnterSearch && onEnterSearch();
					ev.preventDefault();
				}
			}}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<SearchIcon />
					</InputAdornment>
				),
			}}
			sx={{
				'&:hover': {
					'&& fieldset': {
						border: `1px solid ${color.shades.blue[50]}`,
					},
				},
			}}
			className={classes.search}
		/>
	);
};

const useStyles = makeStyles(() => ({
	search: {
		width: '263px',
		margin: '8px !important',
		'& .MuiInputLabel-formControl': {
			...themes.bodyRegular,
			color: color.neutral[5],
		},
		'& .Mui-focused.MuiInputLabel-formControl': {
			color: color.signal.info,
		},
		'& .MuiInputBase-input': {
			...themes.bodyRegular,
			color: color.neutral[8],
			height: '22px !important',
			borderRight: `1px solid ${color.neutral[4]}`,
		},
		'& .MuiOutlinedInput-root': {
			paddingRight: '5px',
		},
		'& .MuiSvgIcon-root': {
			color: color.neutral[5],
		},
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: color.neutral[5],
		},
		'& .Mui-focused .MuiOutlinedInput-notchedOutline': {
			border: `2px solid ${color.signal.info} !important`,
		},
	},
}));
