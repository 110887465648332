import { Backdrop, CircularProgress, Typography } from '@mui/material';
import * as React from 'react';
import { LoaderType } from '../../../types/common.types';
import { makeStyles } from '@mui/styles';
import { colors } from '../../../theme';

const color = colors();
export const Loader = ({ open, message }: LoaderType) => {
	const classes = useStyles();

	return (
		<Backdrop key="loader" sx={{ color: color.neutral[3], zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
			<CircularProgress size={70} sx={{ color: color.signal.infoDark }} />
			<Typography position="absolute" className={classes.loaderText} sx={{ marginTop: '8%' }}>
				{message}
			</Typography>
		</Backdrop>
	);
};

const useStyles = makeStyles(() => ({
	loaderText: {
		color: color.shades.white,
		fontFamily: 'Calibri Light',
		fontStyle: 'Italic',
	},
}));
