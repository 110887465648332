import React, { useEffect, useState } from 'react';
import { GridPagination } from '@mui/x-data-grid';
import { makeStyles } from '@mui/styles';
import { colors } from '../../../theme';
import Pagination from '@mui/material/Pagination';
import { PaginationType } from '../../../types/common.types';

const color = colors();
export const CustomPagination = ({
	totalCount,
	page = 1,
	pageSize,
	onPageChange,
	onRowsPerPageChange,
}: PaginationType) => {
	const classes = useStyles();
	const [currPage, setCurrPage] = useState(page);
	const [rowsPerPage, setRowsPerPage] = useState<number>(pageSize);
	const [pageCount, setPageCount] = useState<number>(1);

	useEffect(() => {
		setPageCount(Math.floor(totalCount / rowsPerPage));
	}, [rowsPerPage, totalCount]);

	const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
		setCurrPage(value);
		onPageChange && onPageChange(value, rowsPerPage);
	};

	const handleRowPerPageChange = (value: string) => {
		setRowsPerPage(parseInt(value));
		onRowsPerPageChange && onRowsPerPageChange(value);
	};

	return (
		<GridPagination
			className={classes.pagination}
			rowsPerPage={rowsPerPage}
			onRowsPerPageChange={(e) => handleRowPerPageChange(e.target.value)}
			ActionsComponent={() => (
				<Pagination
					count={totalCount % rowsPerPage === 0 ? pageCount : pageCount + 1}
					page={currPage}
					onChange={handleChange}
					shape="rounded"
				/>
			)}
			count={totalCount}
		/>
	);
};

const useStyles = makeStyles(() => ({
	pagination: {
		'& .MuiButtonBase-root.Mui-selected': {
			backgroundColor: color.primary[100],
			color: color.shades.white,
		},
		'& .MuiButtonBase-root.Mui-selected:hover': {
			backgroundColor: color.primary[100],
		},
		'& .MuiPagination-root.MuiPagination-text': {
			flexShrink: 0,
			marginLeft: '10px',
		},
	},
}));
