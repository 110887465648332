import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { SingleSelectType } from '_/types/common.types';
import { makeStyles } from '@mui/styles';
import { colors, theme } from '../../../theme';
import { FormHelperText, Select } from '@mui/material';

const color = colors();
const themes = theme().typography;
export const SingleSelectDropDown = ({
	label,
	width = 150,
	data,
	value,
	isTextSelect,
	disabled,
	hasError,
	onSelectCallback,
}: SingleSelectType) => {
	const classes = useStyles();

	return (
		<FormControl sx={{ m: 1, width: { width } }} size="small" className={classes.dropdown} error={hasError}>
			<InputLabel id={`dropdown-input-${label}`}>{label}</InputLabel>
			<Select
				labelId={`dropdown-${label}`}
				id={`dropdown-${label}`}
				value={value}
				label={label}
				disabled={disabled}
				onChange={(e) => onSelectCallback && onSelectCallback(e.target.value)}
				sx={{
					'&:hover': {
						'&& fieldset': {
							border: isTextSelect
								? 'none'
								: disabled
								? `1px solid ${color.neutral[5]}`
								: `1px solid ${color.shades.blue[50]}`,
						},
					},
				}}
			>
				{data?.map((item, i) => (
					<MenuItem value={item.key} key={i}>
						{item.value}
					</MenuItem>
				))}
			</Select>
			{hasError && <FormHelperText>This field is required!</FormHelperText>}
		</FormControl>
	);
};

const useStyles = makeStyles(() => ({
	dropdown: {
		'& .MuiInputLabel-formControl': {
			...themes.bodyRegular,
			color: color.neutral[5],
		},
		'& .Mui-focused.MuiInputLabel-formControl': {
			color: color.signal.info,
		},
		'& .MuiInputBase-input': {
			...themes.bodyRegular,
			color: color.neutral[8],
		},
		'& .MuiSelect-icon': {
			color: color.neutral[5],
		},
		'& .Mui-focused .MuiOutlinedInput-notchedOutline': {
			border: `2px solid ${color.signal.info} !important`,
		},
		'& .MuiSelect-select': {
			display: 'flex',
		},
		'& .MuiFormHelperText-root': {
			marginLeft: '0px',
		},
	},
}));
