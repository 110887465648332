import { Divider, Checkbox, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useCallback, useEffect, useState } from 'react';
import { colors, theme } from '../../theme';
import mockPlatformList from '../../services/mock/platformList.json';
import mockPositionList from '../../services/mock/positionList.json';
import mockProductionGroupList from '../../services/mock/productionGroupList.json';
import { SingleSelectDropDown } from '../../components/shared/dropdown/singleSelect';
import { CustomDate } from '../../components/shared/date/date';
import { TextBox } from '../../components/shared/textbox/textbox';
import { CustomButton } from '../../components/shared/button/button';
import { HistoryTimeline } from '../../components/shared/timeline/timeline';
import { ItemType } from '../../types/common.types';
import { MaterialType, MaterialUpsertType, PositionType } from '../../types/material.types';
import { MultiSelectDropDown } from '../../components/shared/dropdown/multiSelect';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { msalInstance } from '../../config/msalConfig';
import { ToolTip } from '../../components/shared/tooltip/tooltip';

const color = colors();
const themes = theme().typography;
export const MaterialEditPage = (data: MaterialType) => {
	const classes = useStyles();

	const [platformList, setPlatformList] = useState<ItemType[]>([]);
	const [platformName, setPlatformName] = useState<string>(data.platform?.tpid.toString() || '');
	const [position, setPosition] = useState<string[][]>(data.platform?.position.map((p) => p.positionName) || []);
	const [date, setDate] = useState<string[]>(data.platform?.position.map((p) => p.scopeStartDate) || []);
	const [comments, setComments] = useState<string>(data.platform?.comments?.toString() || '');
	const [prodGroup, setProdGroup] = useState<ItemType[]>(
		data.platform?.productionGroupId.map((p) => {
			return { key: p, value: mockProductionGroupList.filter((pg) => pg.key === p)[0].value };
		}) || []
	);

	useEffect(() => {
		const pfList: ItemType[] = [];
		mockPlatformList.map((p) =>
			pfList.push({
				key: p.key,
				value: (
					<>
						<img
							src={require(`../../images/${p.value.toLocaleLowerCase().replaceAll(' ', '')}.png`)}
							alt={p.value}
							width="24px"
							height="24px"
						/>
						{p.value}
					</>
				),
			})
		);
		setPlatformList([...pfList]);
	}, []);

	const addPosition = () => {
		setPosition(() => {
			return [...position, []];
		});
		setDate(() => {
			return [...date, ''];
		});
	};

	let removePosition = (index: number) => {
		setPosition((p) => p.filter((_, i) => i !== index));
		setDate((p) => p.filter((_, i) => i !== index));
	};

	const onDiscardClick = useCallback(() => {
		setPlatformName(data.platform?.tpid.toString() || '');
		setPosition(data.platform?.position.map((p) => p.positionName) || []);
		setDate(data.platform?.position.map((p) => p.scopeStartDate) || []);
		setComments(data.platform?.comments?.toString() || '');
		setProdGroup(
			data.platform?.productionGroupId.map((p) => {
				return { key: p, value: mockProductionGroupList.filter((pg) => pg.key === p)[0].value };
			}) || []
		);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const onSaveClick = useCallback(() => {
		let saveData: MaterialUpsertType[] = [];
		let positionData: PositionType[] = [];
		const loggedInUser = msalInstance.getActiveAccount();

		position.forEach((p, i) => {
			positionData.push({
				positionId: mockPositionList.filter((po) => p.includes(po.value)).map((p) => p.key),
				positionName: p,
				scopeStartDate: date[i],
			});
		});

		saveData.push({
			id: data.id,
			materialId: data.materialId,
			platform: {
				tpid: parseInt(platformName),
				tpName: mockPlatformList.filter((p) => p.key === parseInt(platformName))[0].value,
				productionGroupId: prodGroup.map((p) => p.key),
				productionGroup: prodGroup.map((p) => p.value?.toString() || ''),
				position: [...positionData],
				comments,
			},
			user: {
				loggedInUserName: (loggedInUser && loggedInUser.name?.split('(')[0])?.trim() || '',
				loggedInUserEmail: (loggedInUser && loggedInUser.username) || '',
			},
		});

		alert(JSON.stringify(saveData));
	}, [data, platformName, position, date, prodGroup, comments]);
	return (
		<>
			<div className={classes.materialEdit}>
				<Grid container rowSpacing={1} spacing={2} {...themes.bodyRegular}>
					<Grid item xs={2} color={color.neutral[8]}>
						Material ID
					</Grid>
					<Grid item xs={10} color={color.text.dark}>
						{data.materialId}
					</Grid>
					<Grid item xs={2} color={color.neutral[8]}>
						Platform
					</Grid>
					<Grid item xs={10}>
						<SingleSelectDropDown
							label="Platform"
							data={platformList}
							value={platformName}
							width={476}
							onSelectCallback={setPlatformName}
						/>
					</Grid>
					<Grid item xs={2} color={color.neutral[8]} className={classes.paddingTop0}>
						Position
					</Grid>
					<Grid item xs={10} className={classes.paddingTop0}>
						{position.length > 0 &&
							position.map((p, i) => (
								<div className={classes.position}>
									<MultiSelectDropDown
										label="Position"
										data={mockPositionList.map((list) => list.value)}
										value={p}
										width={210}
										showValue
										onSelectCallback={(e) =>
											setPosition((p) => {
												p[i] = e;
												return [...p];
											})
										}
									/>
									<CustomDate
										label="Scope Start Date"
										value={date[i]}
										width={210}
										onChangeCallback={(e) =>
											setDate((p) => {
												p[i] = e;
												return [...p];
											})
										}
									/>
									{i + 1 < (position.length || 0) ? (
										<ToolTip
											tooltipText="Remove position"
											children={<DeleteOutlineIcon onClick={() => removePosition(i)} />}
										/>
									) : (
										<ToolTip
											tooltipText="Add position"
											children={<AddCircleOutlineIcon onClick={() => addPosition()} />}
										/>
									)}
								</div>
							))}
					</Grid>
					<Grid item xs={2} color={color.neutral[8]} className={classes.paddingTop0}>
						Comments
					</Grid>
					<Grid item xs={10} className={classes.paddingTop0}>
						<TextBox
							id={0}
							label="Leave comments here"
							value={comments}
							width={476}
							isMultiline
							onChangeCallback={setComments}
						/>
					</Grid>
					<Grid item xs={2} color={color.neutral[8]} className={classes.paddingTop0}>
						Production group
					</Grid>
					<Grid item xs={10}>
						<Grid container rowSpacing={1} spacing={2}>
							{mockProductionGroupList.map((pg, i) => (
								<Grid item xs={6} className={classes.paddingTop0}>
									<Checkbox
										checked={prodGroup.filter((p) => p.key === pg.key).length !== 0}
										size="small"
										onChange={(e) =>
											e.target.checked
												? setProdGroup([...prodGroup, { key: pg.key, value: pg.value }])
												: setProdGroup(prodGroup.filter((p) => p.key !== pg.key))
										}
									/>
									<span className={classes.pgGroup}>{pg.value}</span>
								</Grid>
							))}
						</Grid>
					</Grid>
					{data.history && (
						<>
							<Grid item xs={2} color={color.neutral[8]}>
								History
							</Grid>
							<Grid item xs={10} color={color.text.dark}>
								<Divider id="history-divider" />
								<HistoryTimeline logData={data.history} />
							</Grid>
						</>
					)}
				</Grid>
			</div>
			<div className={classes.footer}>
				<CustomButton id="discard" text="Discard" variant="contained" onClickCallback={onDiscardClick} />
				<CustomButton id="save" text="Save" variant="contained" onClickCallback={onSaveClick} />
			</div>
		</>
	);
};

const useStyles = makeStyles(() => ({
	materialEdit: {
		width: '650px',
		height: 'calc(100% - 150px)',
		padding: '0px 24px',
		overflowY: 'scroll',
		'& .MuiInputLabel-formControl': {
			color: color.neutral[5],
		},
		'& .MuiInputBase-input': {
			color: color.text.dark,
		},
		'& .MuiSvgIcon-root': {
			cursor: 'pointer',
		},
		'& .MuiCheckbox-root.Mui-checked': {
			color: color.primary[90],
		},
		'& .MuiDivider-root': {
			padding: '6px',
		},
	},
	paddingTop0: {
		paddingTop: '0px !important',
	},
	position: {
		display: 'flex',
		alignItems: 'center',
	},
	pgGroup: {
		verticalAlign: 'middle',
	},
	footer: {
		position: 'absolute',
		bottom: 0,
		right: '10px',
		width: '100%',
		height: '70px',
		borderTop: `1px solid ${color.neutral[2]}`,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'end',
		marginRight: '10px',
		'& #discard': {
			backgroundColor: color.primary[5],
			color: color.text.dark,
			width: '90px',
		},
		'& #save': {
			backgroundColor: color.primary[100],
			color: color.text.light,
			width: '90px',
		},
	},
}));
