import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { Header } from '../../components/shared/header/header';
import { SortOptions } from '../../config/supplierTabConfig';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { CustomTab } from '../../components/shared/tab/tab';
import { SingleSelectDropDown } from '../../components/shared/dropdown/singleSelect';
import { makeStyles } from '@mui/styles';
import { Search } from '../../components/shared/search/search';
import { CustomButton } from '../../components/shared/button/button';
import { SupplierFilter, SupplierInfoResponse, SupplierMaster, SupplierType } from '../../types/supplier.types';
import { Typography } from '@mui/material';
import { colors, theme } from '../../theme';
import { Panel } from '../../components/shared/panel/panel';
import { SupplierEditPage } from './supplierEdit';
import { SupplierTable } from '../../components/supplier/supplierTable';
import { MultiSelectDropDown } from '../../components/shared/dropdown/multiSelect';
import { ToolTip } from '../../components/shared/tooltip/tooltip';
import { useAsyncOperation } from '../../utils/use-async-operation';
import { getSupplierInfoApi, getTTMaster } from '../../services/supplier-service';
import { Loader } from '../../components/shared/loader/loader';
import { CustomAlert } from '../../components/shared/alert/alert';

const color = colors();
const themes = theme().typography;
export const Supplier = (): ReactElement => {
	const classes = useStyles();

	const [activeTab, setActiveTab] = useState<number>(0);
	const [supplierList, setSupplierList] = useState<SupplierType[]>([]);
	const [selectedRowNum, setSelectedRowNum] = useState<number[]>([]);
	const [selectedRow, setSelectedRow] = useState<SupplierType[]>([]);
	const [activeRow, setActiveRow] = useState<SupplierType>();
	const [platform, setPlatform] = useState<string[]>([]);
	const [scopeStatus, setScopeStatus] = useState<string[]>([]);
	const [spurStatus, setSpurStatus] = useState<string[]>([]);
	const [supplierType, setSupplierType] = useState<string[]>([]);
	const [location, setLocation] = useState<string[]>([]);
	const [searchText, setSearchText] = useState<string>('');
	const [sortOption, setSortOption] = useState<string>('0');
	const [openPanel, setOpenPanel] = useState<boolean>(false);
	const [isRowEdit, setIsRowEdit] = useState<boolean>(false);
	const [isSamePlatform, setIsSamePlatform] = useState<boolean>(false);
	const [appliedFilter, setAppliedFilter] = useState<SupplierFilter>();
	const [currPage, setCurrPage] = useState<number>(1);
	const [rowsPerPage, setRowsPerPage] = useState<number>(25);
	const [startPageLoad, setStartPageLoad] = useState(true);
	const [errorAlertOpen, setErrorAlertOpen] = useState(false);
	const [isSave, setIsSave] = useState(false);
	const [successAlertOpen, setSuccessAlertOpen] = useState(false);

	const [getSupplierInfo, , supplierInfo, supplierError] = useAsyncOperation<SupplierInfoResponse>(getSupplierInfoApi);
	const [getTTMasterData, , ttMasterData] = useAsyncOperation<SupplierMaster>(getTTMaster);

	useEffect(() => {
		getSupplierInfo({ page: 1, recordPerPage: 25, searchText: '', isFilterApplied: false } as SupplierFilter);
		getTTMasterData();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (supplierError) {
			setStartPageLoad(false);
			setErrorAlertOpen(true);
		}
	}, [supplierError]);

	useEffect(() => {
		setSelectedRow([]);
		let data: SupplierType[] = [];
		selectedRowNum.map((i) => data.push(supplierList.filter((s) => s.id === i)[0]));
		setSelectedRow(data);

		let pfIds = data.map((row) => row.platform?.tpId);
		let pfs = data.map((row) => row.platforms?.length);
		setIsSamePlatform(new Set(pfIds).size === 1 && new Set(pfs).size === 1);
	}, [selectedRowNum]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		setSelectedRowNum([]);
		setCurrPage(1);
		let platformIds =
			activeTab === 0
				? ttMasterData?.platforms?.filter((item) => platform.includes(item.value!))?.map((m) => m.key)
				: [activeTab];

		let scopeStatusIds = ttMasterData?.scopeStatuses
			?.filter((item) => scopeStatus.includes(item.value!))
			?.map((m) => m.key);

		let supplierTypeIds = ttMasterData?.supplierTypes
			?.filter((item) => supplierType.includes(item.suTypeName))
			?.map((m) => m.suTypeId);

		let countryIds = ttMasterData?.countries
			?.filter((item) => location.includes(item.hmCountryName))
			?.map((m) => m.geographicalCountryId);

		setStartPageLoad(true);
		getSupplierInfo({
			page: 1,
			recordPerPage: rowsPerPage,
			searchText: searchText,
			isFilterApplied: !(
				!platformIds?.length &&
				!scopeStatusIds?.length &&
				!spurStatus?.length &&
				!supplierTypeIds?.length &&
				!supplierType?.length &&
				!countryIds?.length &&
				searchText.length === 0
			),
			platforms: !platformIds?.length ? undefined : platformIds,
			scopeStatusIds: !scopeStatusIds?.length ? undefined : scopeStatusIds,
			supplierStatuses: !spurStatus?.length ? undefined : spurStatus,
			suTypes: !supplierTypeIds?.length ? undefined : supplierTypeIds,
			suTypeNames: !supplierType?.length ? undefined : supplierType,
			countryIds: !countryIds?.length ? undefined : countryIds,
		} as SupplierFilter);
	}, [activeTab]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (appliedFilter) {
			appliedFilter.page = currPage;
			appliedFilter.recordPerPage = rowsPerPage;
		}
	}, [currPage, rowsPerPage]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (supplierInfo) {
			setSupplierList(supplierInfo?.supplierInfo);
			setStartPageLoad(false);
			setSelectedRowNum([...selectedRowNum]);
			setSuccessAlertOpen(isSave);
		}
	}, [supplierInfo, ttMasterData]);

	useEffect(() => {
		let platformIds =
			activeTab === 0
				? ttMasterData?.platforms?.filter((item) => platform.includes(item.value!))?.map((m) => m.key)
				: [activeTab];

		let scopeStatusIds = ttMasterData?.scopeStatuses
			?.filter((item) => scopeStatus.includes(item.value!))
			?.map((m) => m.key);

		let supplierTypeIds = ttMasterData?.supplierTypes
			?.filter((item) => supplierType.includes(item.suTypeName))
			?.map((m) => m.suTypeId);

		let countryIds = ttMasterData?.countries
			?.filter((item) => location.includes(item.hmCountryName))
			?.map((m) => m.geographicalCountryId);

		setAppliedFilter({
			page: currPage,
			recordPerPage: rowsPerPage,
			platforms: !platformIds?.length ? undefined : platformIds,
			scopeStatusIds: !scopeStatusIds?.length ? undefined : scopeStatusIds,
			supplierStatuses: !spurStatus?.length ? undefined : spurStatus,
			suTypes: !supplierTypeIds?.length ? undefined : supplierTypeIds,
			suTypeNames: !supplierType?.length ? undefined : supplierType,
			countryIds: !countryIds?.length ? undefined : countryIds,
			searchText: searchText,
			isFilterApplied: !(
				!platformIds?.length &&
				!scopeStatusIds?.length &&
				!spurStatus?.length &&
				!supplierTypeIds?.length &&
				!supplierType?.length &&
				!countryIds?.length &&
				searchText.length === 0
			),
		});
	}, [platform, scopeStatus, spurStatus, supplierType, location, searchText]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (isSave) onPanelClose();
	}, [isSave]);

	useEffect(() => {
		if (!successAlertOpen) setIsSave(false);
	}, [successAlertOpen]);

	const onEditClick = useCallback((isRowEdit: boolean) => {
		setOpenPanel(true);
		setIsSave(false);
		setIsRowEdit(isRowEdit);
	}, []);

	const handleSearch = () => {
		setStartPageLoad(true);
		if (appliedFilter) {
			appliedFilter.sortBy = sortOption;
			let platformIds =
				activeTab === 0
					? undefined
					: ttMasterData?.platforms?.filter((item) => item.key === activeTab)?.map((m) => m.key);
			if (platformIds && !appliedFilter.platforms) {
				appliedFilter.platforms = platformIds;
			}
		}
		getSupplierInfo(appliedFilter);
	};

	const onClearClick = () => {
		setPlatform([]);
		setScopeStatus([]);
		setSpurStatus([]);
		setSupplierType([]);
		setLocation([]);
		setSearchText('');
		setStartPageLoad(true);
		getSupplierInfo({
			page: currPage,
			recordPerPage: rowsPerPage,
			searchText: '',
			isFilterApplied: activeTab !== 0,
			platforms: activeTab === 0 ? undefined : [activeTab],
		} as SupplierFilter);
	};

	const onPanelClose = () => {
		setOpenPanel(false);
		setIsRowEdit(false);
		if (isSave) {
			handleSearch();
		}
	};

	return (
		<div>
			<Loader key="supplier-loader" open={startPageLoad} message="Loading..." />
			<Header title="Suppliers" />
			{errorAlertOpen && (
				<CustomAlert
					open={errorAlertOpen}
					message="Something went wrong while retrieving supplier data!"
					severity="error"
					timeout={5000}
					isFade={false}
					onCloseCallback={() => {
						setErrorAlertOpen(false);
					}}
				/>
			)}
			<div className={classes.topPadding}>
				<CustomTab data={ttMasterData && ttMasterData?.platforms!} value={activeTab} onChangeCallback={setActiveTab} />
			</div>
			<div className={classes.topPadding}>
				{activeTab === 0 && (
					<MultiSelectDropDown
						label="Platform"
						data={ttMasterData && ttMasterData?.platforms?.filter((x) => x.key !== 0).map((list) => list?.value!)}
						value={platform}
						onSelectCallback={setPlatform}
					/>
				)}
				<MultiSelectDropDown
					label="Scope Status"
					data={ttMasterData && ttMasterData?.scopeStatuses?.map((list) => list.value!)}
					value={scopeStatus}
					onSelectCallback={setScopeStatus}
				/>
				<MultiSelectDropDown
					label="Spur Status"
					data={ttMasterData && ttMasterData?.supplierStatuses?.map((list) => list.supuStatusName)}
					value={spurStatus}
					onSelectCallback={setSpurStatus}
				/>
				<MultiSelectDropDown
					label="Supplier Type"
					data={ttMasterData && ttMasterData?.supplierTypes?.map((list) => list.suTypeName)}
					value={supplierType}
					onSelectCallback={setSupplierType}
				/>
				<MultiSelectDropDown
					label="Location"
					data={ttMasterData && ttMasterData.countries.map((list) => list.hmCountryName)}
					value={location}
					onSelectCallback={setLocation}
				/>
				<Search
					placeholder="Search Supplier Name, SPUR ID..."
					value={searchText}
					onSearchCallback={setSearchText}
					onEnterSearch={handleSearch}
				/>
				<CustomButton id="search" text="Search" width={90} variant="outlined" onClickCallback={handleSearch} />
				{(platform.length > 0 ||
					scopeStatus.length > 0 ||
					spurStatus.length > 0 ||
					supplierType.length > 0 ||
					location.length > 0 ||
					searchText) && <CustomButton id="clear" text="Clear" width={90} onClickCallback={onClearClick} />}
			</div>
			<div className={classes.actions}>
				<Typography
					{...themes.bodyRegular}
					alignItems="center"
					display="flex"
					color={color.neutral[8]}
					marginLeft="8px"
				>
					Sort By:
				</Typography>
				<SingleSelectDropDown
					label=""
					data={SortOptions}
					value={sortOption}
					onSelectCallback={setSortOption}
					isTextSelect
				/>
				{selectedRowNum.length > 0 && (
					<>
						<Typography
							{...themes.bodyRegular}
							alignItems="center"
							display="flex"
							color={color.text.dark}
							margin="0px 8px"
						>
							{`${selectedRowNum.length} item(s) are selected`}
						</Typography>

						<ToolTip
							width={200}
							tooltipText={
								isSamePlatform
									? ''
									: 'Sorry, cross-platform bulk editing is not supported now. Please select suppliers on the same platform.'
							}
							tooltipColor={color.shades.red[10]}
							tooltipTextColor={color.shades.red[70]}
							children={
								<span>
									<CustomButton
										id="edit"
										text="Edit"
										width={69}
										disabled={!isSamePlatform}
										startIcon={<EditOutlinedIcon />}
										onClickCallback={onEditClick}
									/>
								</span>
							}
						/>
						<ToolTip
							width={200}
							tooltipText={
								isSamePlatform
									? ''
									: 'Sorry, cross-platform bulk editing is not supported now. Please select suppliers on the same platform.'
							}
							tooltipColor={color.shades.red[10]}
							tooltipTextColor={color.shades.red[70]}
							children={
								<span>
									<CustomButton
										id="send-email"
										text="Send Email"
										width={113}
										disabled={!isSamePlatform}
										startIcon={<EmailOutlinedIcon />}
										href={`mailto:${selectedRow.map((r) => r.contact)}?body=Hi Team,`}
									/>
								</span>
							}
						/>
					</>
				)}
			</div>
			{supplierList && (
				<SupplierTable
					data={supplierList}
					totalCount={supplierInfo?.totalCount || 0}
					onRowSelectedCallback={setSelectedRowNum}
					onCellClickCallback={setActiveRow}
					onEditClickCallback={() => onEditClick(true)}
					onEmailClickHref={`mailto:${activeRow?.contact}?body=Hi Team,`}
					onPageChange={(a, b) => {
						setCurrPage(a);
						setRowsPerPage(b);
						setTimeout(() => {
							handleSearch();
						}, 500);
					}}
					page={currPage}
					rowsPerPage={rowsPerPage}
					onRowsPerPageChange={(a) => {
						setRowsPerPage(a);
						setTimeout(() => {
							handleSearch();
						}, 500);
					}}
				/>
			)}
			{!isRowEdit && selectedRow && (
				<Panel
					anchor="right"
					open={openPanel}
					title={selectedRow.length > 1 ? 'Bulk edit' : selectedRow[0]?.supplierName}
					onCloseCallback={onPanelClose}
					component={
						<SupplierEditPage
							key="supplier-edit"
							rows={selectedRow}
							isBulk={selectedRow.length > 1}
							supplierPlatforms={ttMasterData && ttMasterData?.platforms!}
							supplierScopeStatuses={ttMasterData && ttMasterData?.scopeStatuses!}
							isSave={setIsSave}
						/>
					}
				/>
			)}
			{isRowEdit && activeRow && (
				<Panel
					anchor="right"
					open={openPanel}
					title={activeRow.supplierName}
					onCloseCallback={onPanelClose}
					component={
						<SupplierEditPage
							rows={[activeRow]}
							isBulk={false}
							supplierPlatforms={ttMasterData && ttMasterData?.platforms!}
							supplierScopeStatuses={ttMasterData && ttMasterData?.scopeStatuses!}
							isSave={setIsSave}
						/>
					}
				/>
			)}
			{successAlertOpen && (
				<div className={classes.successAlert}>
					<CustomAlert
						open={successAlertOpen}
						timeout={5000}
						message="Saved successfully!"
						severity="success"
						onCloseCallback={() => setSuccessAlertOpen(false)}
					/>
				</div>
			)}
		</div>
	);
};

const useStyles = makeStyles(() => ({
	topPadding: {
		paddingTop: '15px',
		'& #clear': {
			backgroundColor: color.primary[100],
			color: color.text.light,
		},
	},
	actions: {
		display: 'flex',
		'& .MuiFormControl-root': {
			margin: '8px 0px',
		},
		'& .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
		'& .MuiButton-root': {
			...themes.bodyRegular,
			border: 'none',
			padding: '0px',
		},
		'& .MuiButton-root.Mui-disabled': {
			fontWeight: 400,
			border: 'none',
			padding: '0px',
		},
		'& .MuiButton-root:hover': {
			border: 'none',
		},
	},
	successAlert: {
		position: 'fixed',
		top: '10px',
		left: '50%',
		transform: 'translateX(-50%)',
	},
}));
