import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { TableType } from '../../../types/common.types';
import { makeStyles } from '@mui/styles';
import { colors, theme } from '../../../theme';
import { CustomPagination } from '../pagination/pagination';
import { Box } from '@mui/material';

const color = colors();
const themes = theme().typography;
export const Table = ({
	width = '100%',
	height = '100%',
	columns,
	data,
	totalCount,
	page = 1,
	pageSize,
	pageSizeOptions,
	checkbox,
	onRowSelectedCallback,
	onCellClickCallback,
	onPageChange,
	onRowsPerPageChange,
}: TableType) => {
	const classes = useStyles();

	return (
		<Box className={classes.table} sx={{ width: { width }, height: { height } }}>
			<DataGrid
				rows={data}
				columns={columns}
				pageSizeOptions={pageSizeOptions}
				slots={{
					pagination: () =>
						CustomPagination({
							totalCount: totalCount || data.length,
							pageSize,
							page,
							onPageChange,
							onRowsPerPageChange,
						}),
				}}
				checkboxSelection={checkbox}
				onRowSelectionModelChange={(rows) => onRowSelectedCallback && onRowSelectedCallback(rows)}
				disableRowSelectionOnClick
				onCellClick={(row) => onCellClickCallback && onCellClickCallback(row.row)}
				sx={{ height: '100%' }}
			/>
		</Box>
	);
};

const useStyles = makeStyles(() => ({
	table: {
		position: 'absolute',
		paddingTop: 10,
		'& .MuiDataGrid-root': {
			borderWidth: 0,
		},
		'& .MuiDataGrid-columnHeaders': {
			borderTop: `1px solid ${color.neutral[2]} !important`,
			borderBottom: `1px solid ${color.neutral[2]} !important`,
		},
		'& .MuiDataGrid-columnHeaderTitle': {
			...themes.bodyMedium,
			color: color.neutral[8],
			whiteSpace: 'normal',
		},
		'& .MuiCheckbox-root.Mui-checked': {
			color: color.primary[90],
		},
		'& .MuiDataGrid-row.Mui-selected': {
			backgroundColor: `${color.neutral[1]} !important`,
		},
		'& .MuiDataGrid-row:hover': {
			backgroundColor: `${color.shades.blue[10]} !important`,
		},
		'& .MuiDataGrid-cell': {
			...themes.bodyRegular,
			color: color.text.dark,
			borderBottom: `1px solid ${color.neutral[2]} !important`,
		},
		'& .MuiTablePagination-selectLabel': {
			...themes.bodyRegular,
			color: color.neutral[8],
		},
		'& .MuiTablePagination-displayedRows': {
			...themes.bodyRegular,
			color: color.neutral[8],
		},
		'& .MuiTablePagination-actions': {
			color: color.neutral[8],
		},
		'& .MuiDataGrid-selectedRowCount': {
			visibility: 'hidden',
		},
		'& .MuiDataGrid-footerContainer': {
			borderTop: `1px solid ${color.neutral[2]} !important`,
		},
		'& .MuiDataGrid-row:hover #action-buttons': {
			visibility: 'visible',
		},
	},
}));
