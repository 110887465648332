import { Alert, Collapse, IconButton } from '@mui/material';
import * as React from 'react';
import { AlertType } from '../../../types/common.types';
import CloseIcon from '@mui/icons-material/Close';
import CancelIcon from '@mui/icons-material/Cancel';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { useEffect } from 'react';
import Fade from '@mui/material/Fade';

export const CustomAlert = ({ open, severity, message, timeout, isFade, onCloseCallback }: AlertType) => {
	useEffect(() => {
		if (timeout && timeout > 0) {
			setTimeout(() => {
				onCloseCallback && onCloseCallback(false);
				open = !open;
			}, timeout);
		}
	}, [timeout]); /* eslint react-hooks/exhaustive-deps: off */

	return (
		<>
			{isFade ? (
				<Fade timeout={2000} in={open} exit={!open}>
					<Alert
						icon={severity === 'error' ? <CancelIcon /> : <DoneAllIcon />}
						severity={severity === 'error' ? 'error' : 'success'}
						action={
							<IconButton
								aria-label="close"
								color="inherit"
								size="small"
								onClick={() => onCloseCallback && onCloseCallback(false)}
							>
								<CloseIcon fontSize="inherit" />
							</IconButton>
						}
						sx={{ mb: 2 }}
					>
						{message}
					</Alert>
				</Fade>
			) : (
				<Collapse key="alert-banner" in={open}>
					<Alert
						icon={severity === 'error' ? <CancelIcon /> : <DoneAllIcon />}
						severity={severity === 'error' ? 'error' : 'success'}
						action={
							<IconButton
								aria-label="close"
								color="inherit"
								size="small"
								onClick={() => onCloseCallback && onCloseCallback(false)}
							>
								<CloseIcon fontSize="inherit" />
							</IconButton>
						}
						sx={{ mb: 2 }}
					>
						{message}
					</Alert>
				</Collapse>
			)}
		</>
	);
};
