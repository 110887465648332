import * as React from 'react';
import Button from '@mui/material/Button';
import { ButtonType } from '../../../types/common.types';
import { makeStyles } from '@mui/styles';
import { colors, theme } from '../../../theme';

const color = colors();
const themes = theme().typography;
export const CustomButton = ({
	id,
	text,
	width,
	variant,
	disabled,
	startIcon,
	endIcon,
	onClickCallback,
	href,
}: ButtonType) => {
	const classes = useStyles();

	return (
		<div className={classes.button}>
			<Button
				id={id}
				sx={{ width: { width } }}
				variant={variant || 'outlined'}
				disabled={disabled}
				onClick={() => onClickCallback && onClickCallback()}
				startIcon={startIcon}
				endIcon={endIcon}
				href={href}
			>
				{text}
			</Button>
		</div>
	);
};

const useStyles = makeStyles(() => ({
	button: {
		display: 'inline-flex',
		margin: '8px !important',
		alignItems: 'center',
		'& .MuiButton-root': {
			height: '39px',
			textTransform: 'capitalize',
			color: color.primary[100],
			borderColor: color.primary[100],
			...themes.bodyMedium,
		},
		'& .MuiButton-root:hover': {
			borderColor: color.primary[100],
			backgroundColor: color.neutral[2],
		},
	},
}));
